<template>
  <div
    class="AiDatePicker-ActionRow"
    :class="{
      'AiDatePicker-ActionRow--mobileHidden':
        !fullyCompleted && !customProps.isDesktop,
    }">
    <div class="AiDatePicker-ActionRow-Informations">
      <ai-icon class="AiDatePicker-icon" name="calendar" :size="24" />
      <ai-typo
        as="p"
        variant="paragraph-04-bold"
        class="AiDatePicker-ActionRow-Item">
        {{ date }}
      </ai-typo>

      <ai-icon v-if="date" class="AiDatePicker-icon" name="night" :size="24" />
      <ai-typo
        v-if="date"
        as="p"
        variant="paragraph-04-bold"
        class="AiDatePicker-ActionRow-Item">
        {{
          $t('search.datePicker.numberNights', {
            count: numberNights,
          })
        }}
      </ai-typo>
    </div>

    <div class="AiDatePicker-ActionRow--desktop">
      <ai-button variant="tertiary" slim @click="resetDates">
        {{ $t('search.datePicker.reset') }}
      </ai-button>
    </div>

    <div class="AiDatePicker-ActionRow--mobile">
      <ai-button
        variant="tertiary"
        slim
        :label="$t('search.datePicker.clearAll')"
        @click="resetDates" />
      <ai-button
        ref="selectDateRef"
        data-testid="applyDatePicker"
        :label="$t('search.datePicker.apply')"
        @click="emit('selectDate')" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { differenceInCalendarDays } from 'date-fns';

import { formatDate, FORMATS } from '~~/helpers/date.helper';

export interface AiDatePickerActionRowProps {
  internalModelValue: Date | Date[] | null;
  customProps: Record<string, any>;
}

interface AiDatePickerActionRowEmits {
  (event: 'selectDate'): void;
  (event: 'clearDates'): void;
  (event: 'selectInternalDates', value: Date[]): void;
}

const props = defineProps<AiDatePickerActionRowProps>();
const emit = defineEmits<AiDatePickerActionRowEmits>();
const { t } = useI18n();

const selectDateRef = ref<ComponentPublicInstance | undefined>();

const datepickerInternalValue = useDatepickerInternalValue();

const internalValue = computed<Date | Date[] | null>(
  () => props.internalModelValue,
);

watch(internalValue, () => {
  if (Array.isArray(internalValue.value)) {
    datepickerInternalValue.updateDate(internalValue.value);
    emit('selectInternalDates', internalValue.value);
  }
});

const isInitialDate = (d1: Date, d2: Date) => {
  if (!d1 || !d2) return false;

  return d1.getTime() === d2.getTime();
};

const fullyCompleted = computed(() => {
  return (
    (props.customProps.doubleCalendar &&
      Array.isArray(props.internalModelValue) &&
      Boolean(props.internalModelValue[0]) &&
      Boolean(props.internalModelValue[1])) ||
    (!props.customProps.doubleCalendar && Boolean(props.internalModelValue))
  );
});

const numberNights = computed(() => {
  if (
    !Array.isArray(props.internalModelValue) ||
    (Array.isArray(props.internalModelValue) &&
      (!props.internalModelValue[0] || !props.internalModelValue[1]))
  ) {
    return 0;
  }

  const startDate = props.internalModelValue[0];
  const endDate = props.internalModelValue[1];

  return differenceInCalendarDays(endDate, startDate);
});

const resetDates = () => {
  emit('clearDates');
};

const dateFnsLocale = useDateFnsLocale();

const date = computed(() => {
  if (props.customProps.doubleCalendar) {
    const internalModelValue = props.internalModelValue as Date[];
    if (!internalModelValue) {
      return t('search.datePicker.anyDates');
    }

    const firstDate = internalModelValue[0] || props.customProps.initialDate;
    const secondDate = internalModelValue[1] || props.customProps.initialDate;

    let formattedDate = '';

    if (!isInitialDate(firstDate, props.customProps.initialDate)) {
      formattedDate += `${formatDate(firstDate, FORMATS.datePickerFormat, {
        locale: dateFnsLocale.value,
      })}`;
    }

    if (!isInitialDate(secondDate, props.customProps.initialDate)) {
      formattedDate += ` - ${formatDate(secondDate, FORMATS.datePickerFormat, {
        locale: dateFnsLocale.value,
      })}`;
    }

    return formattedDate;
  } else if (
    !isInitialDate(
      (props.internalModelValue as Date) || props.customProps.initialDate,
      props.customProps.initialDate,
    )
  ) {
    return `${formatDate(
      props.internalModelValue as Date,
      FORMATS.datePickerFormat,
      { locale: dateFnsLocale.value },
    )}`;
  }
});

defineExpose({
  internalValue,
  selectDateRef,
});
</script>

<style lang="scss" scoped>
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/mq';
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/functions';

.AiDatePicker-ActionRow {
  margin-top: constants.$margin-01;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (mq.$upto-medium) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 137px;
    background-color: white;
    z-index: 10;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.AiDatePicker-ActionRow-Informations {
  position: relative;
  padding: constants.$padding-01;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  @media (mq.$upto-medium) {
    &::after {
      position: absolute;
      content: '';
      height: 1px;
      width: calc(100% - (constants.$padding-01 * 2));
      left: constants.$padding-01;
      right: constants.$padding-01;
      bottom: 0;
      background-color: colors.$gold-300;
    }
  }

  @media (mq.$from-large) {
    margin: 0;
    padding: 0;
  }
}

.AiDatePicker-ActionRow-Item {
  margin-left: constants.$margin-00;
  margin-right: constants.$margin-01;
}

.AiDatePicker-ActionRow--desktop {
  @media (mq.$upto-medium) {
    display: none;
  }
}

.AiDatePicker-ActionRow--mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: calc(100% - constants.$padding-02);
  box-sizing: border-box;
  padding-top: constants.$padding-01;

  @media (mq.$from-large) {
    display: none;
  }
}

.AiDatePicker-ActionRow--mobileHidden {
  display: none;
}

.AiDatePicker-icon {
  color: colors.$gold-700;
}
</style>
