<template>
  <div>
    <slot v-if="isLoading" name="displayed-input">
      <ai-input v-model="fake" :label="$t('search.filter.value.anyDates')" />
    </slot>

    <client-only v-else>
      <!-- Since ref has default/falsy value, lets mount anything and wait for dom size before mounting the proper component  -->
      <div v-if="isDesktop === -1" />

      <ai-desktop-picker
        v-if="isDesktop === 1"
        ref="desktopPicker"
        :custom-props="customProps"
        :bypass-teleport="bypassTeleport"
        :disabled="disabled"
        :minimum-stay-duration="minimumStayDuration"
        :model-value="modelValue"
        auto-apply
        @calendar-changed="updateDayTabIndexes"
        @update:model-value="updateValue">
        <template #displayed-input>
          <slot name="displayed-input" />
        </template>
      </ai-desktop-picker>

      <ai-mobile-picker
        v-else
        ref="mobilePicker"
        :auto-apply="false"
        :custom-props="customProps"
        :disabled="disabled"
        :inline="true"
        :minimum-stay-duration="minimumStayDuration"
        :model-value="modelValue"
        @calendar-changed="updateDayTabIndexes"
        @update:model-value="updateValue"
        @select-date="$emit('selectDate')" />
    </client-only>
  </div>
</template>

<script lang="ts" setup>
import type { DatePickerInstance } from '@vuepic/vue-datepicker';

import { breakpoints } from '~/helpers';

import { AiDesktopPicker, AiMobilePicker } from '../AiDatePicker';

const datepickerInternalValue = useDatepickerInternalValue();

type MultiDatePickerProps = {
  modelValue: Date | Date[];
  minimumStayDuration?: number;
  bypassTeleport?: boolean;
  disabled?: boolean;
};
const props = defineProps<MultiDatePickerProps>();

type MultiDatePickerEmits = {
  (event: 'update:modelValue', value: Date | Date[]): void;
  (event: 'selectDate'): void;
};
const emit = defineEmits<MultiDatePickerEmits>();

const desktopPicker = ref();
const mobilePicker = ref();
const isDesktop = ref(-1);
const isLoading = ref(true);
const fake = ref();

const customProps = reactive({
  // Allow the customComponent to know if he'll be doubled or not
  doubleCalendar: Array.isArray(props.modelValue),
  initialDate: new Date(),
  isDesktop,
});

const updateValue = (newValue: Date | Date[]) => {
  if (Array.isArray(newValue)) {
    datepickerInternalValue.updateDate(newValue);
  }
  emit('update:modelValue', newValue);
};

const onResize = () => {
  nextTick(() => {
    isDesktop.value =
      document.documentElement.clientWidth >= breakpoints.md ? 1 : 0;
  });
};

const openDatepicker = () => {
  if (isDesktop.value) {
    if (!desktopPicker.value) return;

    desktopPicker.value.openDatePicker();
  } else {
    if (!mobilePicker.value) return;

    mobilePicker.value.openDatePicker();
  }
};

onMounted(() => {
  if (process.server) return;

  isLoading.value = false;

  nextTick(() => {
    isDesktop.value =
      document.documentElement.clientWidth >= breakpoints.md ? 1 : 0;
  });

  window.addEventListener('resize', onResize);
});

onUnmounted(() => {
  if (process.server) return;

  window.removeEventListener('resize', onResize);
});

const updateDayTabIndexes = (datepickerRef: DatePickerInstance | undefined) => {
  const calendarItemElements: HTMLDivElement[] =
    datepickerRef?.$el.querySelectorAll('.dp__calendar_item');
  if (!calendarItemElements) {
    return;
  }

  for (const calendarItemElement of calendarItemElements) {
    const ariaDisabled = calendarItemElement.ariaDisabled === 'true';
    const isOffsetDay = !!calendarItemElement.querySelector('.dp__cell_offset');

    calendarItemElement.tabIndex = ariaDisabled || isOffsetDay ? -1 : 0;
  }
};

defineExpose({ openDatepicker });
</script>
