<template>
  <div ref="container" v-click-away="closeOpen" class="AiFieldRoomAndGuest">
    <ai-field-input
      class="AiFieldRoomAndGuest-input"
      :label="label"
      :placeholder="t('booking.bar.inputs.roomAndGuest.placeholder')"
      :name="name"
      :errors="errors"
      :successes="successes"
      :disabled="disabled"
      :model-value="roomAndGuestsText"
      :inverse="inverse"
      readonly
      aria-role="button"
      :aria-controls="`AiFieldRoomAndGuest-dropdown`"
      :expanded="opened"
      @click="toggleOpen"
      @keydown.esc="closeOpen"
      @keydown.prevent.enter="toggleOpen"
      @keydown.prevent.space="toggleOpen">
      <template #right>
        <ai-icon
          name="chevron-down"
          :size="16"
          :color="inverse ? 'gold-400' : 'gold-800'" />
      </template>
    </ai-field-input>

    <div
      v-show="opened"
      id="AiFieldRoomAndGuest-dropdown"
      ref="menu"
      class="AiFieldRoomAndGuest-dropdown"
      :class="{ 'AiFieldRoomAndGuest-dropdown--openTop': openTop || menuTop }"
      @keydown.esc="closeOpen">
      <ai-room-and-guest-form
        ref="roomAndGuestForm"
        v-model="rooms"
        :show-children-age-errors="showChildrenAgeErrors"
        :children-age-errors="childrenAgeErrors"
        :children-max-age="childrenMaxAge" />
    </div>
  </div>
</template>

<script setup lang="ts">
import throttle from 'lodash/throttle';

import AiRoomAndGuestForm from './AiRoomAndGuestForm.vue';

import { directive as vClickAway } from '~~/directives/click-away';

type Guests = {
  adults: number;
  childrenAges?: number[];
};

type Props = {
  modelValue: Guests[];
  readonly?: boolean;
  openTop?: boolean;
  childrenMaxAge?: number;
  // Field props
  label?: string;
  name?: string;
  errors?: string[];
  successes?: string[];
  disabled?: boolean;
  inverse?: boolean;
};

type Emits = {
  (event: 'update:modelValue', value: Props['modelValue']): void;
};

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  errors: undefined,
  inverse: false,
  label: undefined,
  modelValue: undefined,
  name: undefined,
  positionThreshold: 210,
  readonly: false,
  successes: undefined,
  childrenMaxAge: undefined,
});
const emits = defineEmits<Emits>();

const container = ref<HTMLDivElement>();
const menu = ref<HTMLDivElement>();
const menuTop = ref<boolean>(false);
const opened = ref(false);
const showChildrenAgeErrors = ref(false);

const toggleOpen = () => {
  if (!props.disabled) {
    if (opened.value) {
      closeOpen();
    } else {
      opened.value = !opened.value;

      nextTick(() => {
        positionMenu();
      });
    }
  }
};

const rooms = computed({
  get() {
    return props.modelValue;
  },
  set(value: Props['modelValue']) {
    emits('update:modelValue', value);
  },
});

const { t } = useI18n();

const roomAndGuestsText = computed(() => {
  if (!rooms.value || (rooms.value && !rooms.value.length)) return;

  const roomsLabel = t('booking.bar.inputs.roomAndGuest.room', {
    count: rooms.value.length,
  });

  const guestsCount = (props.modelValue || []).reduce(
    (sum, room) => sum + room.adults + (room.childrenAges || []).length,
    0,
  );

  const guestsLabel = t('booking.bar.inputs.roomAndGuest.guest', {
    count: guestsCount,
  });

  return t('booking.bar.inputs.roomAndGuest.fullValue', {
    guests: guestsLabel,
    rooms: roomsLabel,
  });
});

const onBodyClick = (event: MouseEvent) => {
  if (!container.value) return;

  const clickIn = container.value.contains(event.target as Node);

  if (clickIn) return;

  closeOpen();
};

const childrenAgeErrors = computed(() => {
  const errors: boolean[][] = [];

  for (let i = 0; i < rooms.value.length; i++) {
    const room = rooms.value[i];
    errors.push([]);

    if (!room.childrenAges?.length) continue;

    for (let j = 0; j < room.childrenAges.length; j++) {
      const childrenAge = room.childrenAges[j];
      errors[i][j] = typeof childrenAge !== 'number' || childrenAge < 0;
    }
  }

  return errors;
});

const closeOpen = () => {
  const canClose = rooms.value.every(room =>
    room.childrenAges?.every(age => typeof age === 'number' && age >= 0),
  );

  if (!canClose) {
    showChildrenAgeErrors.value = true;
    return;
  }

  opened.value = false;
  showChildrenAgeErrors.value = false;
};

const positionMenu = throttle(() => {
  if (!process.client) return;
  if (!menu.value || !container.value) return;

  const { height } = menu.value.getBoundingClientRect();
  const { bottom } = container.value.getBoundingClientRect();
  const currentWindowHeight = window.innerHeight;

  menuTop.value = bottom + height >= currentWindowHeight;
}, 200);

onMounted(() => {
  document.addEventListener('scroll', positionMenu);
  document.addEventListener('click', onBodyClick);
});

onBeforeUnmount(() => {
  document.removeEventListener('scroll', positionMenu);
  document.removeEventListener('click', onBodyClick);
});
</script>

<style lang="scss" scoped>
@use '@/assets/styles/utilities/mixins';
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/fonts';

.AiFieldRoomAndGuest {
  position: relative;
}

.AiFieldRoomAndGuest-input {
  cursor: pointer;
}

.AiFieldRoomAndGuest-dropdown {
  margin-left: -(constants.$margin-01);
  background: colors.$white;
  padding: constants.$margin-01;
  position: absolute;
  z-index: 150;
  border: 1px solid colors.$gold-200;
  max-height: 50vh;
  overflow: scroll;

  @include mixins.rem-fallback(margin-top, -5);
  @include mixins.rem-fallback(width, 320);

  &--openTop {
    max-height: 85vh;
    overflow: auto;
    bottom: 100%;
  }
}
.AiFieldRoomAndGuest-dropdown::-webkit-scrollbar {
  width: constants.$inner-01;
  @include mixins.rem-fallback(height, 40);
  background-color: transparent;
}

.AiFieldRoomAndGuest-dropdown::-webkit-scrollbar-thumb {
  background: colors.$gold-700;
}
</style>
