<template>
  <div class="AiMobileDatePicker-header">
    <button class="AiMobileDatePicker-headerItem" @click="onFirstHeaderClick">
      <div class="AiMobileDatePicker-checkLabel">
        <ai-typo as="p" variant="tab-link-02">
          {{ $t('search.datePicker.checkIn') }}
        </ai-typo>
      </div>

      <ai-typo
        class="AiMobileDatePicker-date"
        :class="{
          'AiMobileDatePicker-date--outline': selectedDateStatus === 'first',
        }"
        variant="paragraph-04-regular"
        as="p">
        {{ firstDate }}
      </ai-typo>
    </button>

    <button class="AiMobileDatePicker-headerItem">
      <div class="AiMobileDatePicker-checkLabel">
        <ai-typo as="p" variant="tab-link-02">
          {{ $t('search.datePicker.checkOut') }}
        </ai-typo>
      </div>

      <ai-typo
        class="AiMobileDatePicker-date"
        :class="{
          'AiMobileDatePicker-date--outline':
            selectedDateStatus === 'second' || selectedDateStatus === 'all',
        }"
        variant="paragraph-04-regular"
        as="p">
        {{ secondDate }}
      </ai-typo>
    </button>
  </div>

  <div class="AiMobileDatePicker-weekdays">
    <ai-typo
      class="AiMobileDatePicker-weekdays--item"
      variant="paragraph-04-bold"
      as="p">
      {{ $t('search.datePicker.weekdays.monday') }}
    </ai-typo>

    <ai-typo
      class="AiMobileDatePicker-weekdays--item"
      variant="paragraph-04-bold"
      as="p">
      {{ $t('search.datePicker.weekdays.tuesday') }}
    </ai-typo>

    <ai-typo
      class="AiMobileDatePicker-weekdays--item"
      variant="paragraph-04-bold"
      as="p">
      {{ $t('search.datePicker.weekdays.wednesday') }}
    </ai-typo>

    <ai-typo
      class="AiMobileDatePicker-weekdays--item"
      variant="paragraph-04-bold"
      as="p">
      {{ $t('search.datePicker.weekdays.thursday') }}
    </ai-typo>

    <ai-typo
      class="AiMobileDatePicker-weekdays--item"
      variant="paragraph-04-bold"
      as="p">
      {{ $t('search.datePicker.weekdays.friday') }}
    </ai-typo>

    <ai-typo
      class="AiMobileDatePicker-weekdays--item"
      variant="paragraph-04-bold"
      as="p">
      {{ $t('search.datePicker.weekdays.saturday') }}
    </ai-typo>

    <ai-typo
      class="AiMobileDatePicker-weekdays--item"
      variant="paragraph-04-bold"
      as="p">
      {{ $t('search.datePicker.weekdays.sunday') }}
    </ai-typo>
  </div>

  <div
    class="AiMobileDatePicker-picker"
    :class="{
      'AiMobileDatePicker-picker--withRestrictions':
        Boolean(minimumStayDuration),
      'AiMobileDatePicker-picker--filled': Boolean(
        internalDates && internalDates[0] && internalDates[1],
      ),
    }">
    <ai-multi-date-picker
      v-model="dates"
      :minimum-stay-duration="minimumStayDuration"
      @select-date="$emit('selectDate')" />
  </div>
</template>

<script setup lang="ts">
import AiTypo from '../../atoms/AiTypo/AiTypo.vue';
import AiMultiDatePicker from '../AiDatePicker/AiMultiDatePicker.vue';

import { formatDate, FORMATS } from '~~/helpers/date.helper';

const { dates: internalDates, updateDate } = useDatepickerInternalValue();

type Emits = {
  (event: 'update:modelValue', value: Date | Date[]): void;
  (event: 'selectDate'): void;
};

type Props = {
  modelValue: Date[];
  minimumStayDuration?: number;
};

const emits = defineEmits<Emits>();
const props = defineProps<Props>();

const { t } = useI18n();

const dates = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emits('update:modelValue', val);
  },
});

const dateFnsLocale = useDateFnsLocale();

const firstDate = computed(() => {
  if (Array.isArray(internalDates.value) && internalDates.value[0]) {
    return formatDate(internalDates.value[0], FORMATS.readableFormat, {
      locale: dateFnsLocale.value,
    });
  }

  // eslint-disable-next-line sonarjs/no-duplicate-string
  return t('search.datePicker.selectLabel');
});

const secondDate = computed(() => {
  if (Array.isArray(internalDates.value) && internalDates.value[1]) {
    return formatDate(internalDates.value[1], FORMATS.readableFormat, {
      locale: dateFnsLocale.value,
    });
  }

  return t('search.datePicker.selectLabel');
});

const selectedDateStatus = computed(() => {
  if (firstDate.value === secondDate.value) {
    return 'first';
  } else if (
    firstDate.value !== t('search.datePicker.selectLabel') &&
    secondDate.value === t('search.datePicker.selectLabel')
  ) {
    return 'second';
  } else {
    return 'all';
  }
});

const onFirstHeaderClick = () => {
  dates.value = [];
  updateDate([]);
};
</script>

<style lang="scss" scoped>
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/mixins';

.AiMobileDatePicker-header {
  display: flex;
  flex-direction: row;
  padding: constants.$padding-02 0 constants.$padding-03 0;
  background-color: colors.$gold-100;
}

.AiMobileDatePicker-headerItem {
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.AiMobileDatePicker-weekdays {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: constants.$padding-00;
  border-bottom: 1px solid colors.$gold-600;
  background-color: colors.$gold-100;

  &--item {
    width: calc(100% / 7);
    text-align: center;
    padding-bottom: constants.$padding-00;
  }
}

.AiMobileDatePicker-date {
  margin-top: constants.$margin-00;
  padding-bottom: constants.$padding-00;
  width: 100%;

  &--outline {
    border-bottom: 2px solid colors.$gold-700;
  }
}

.AiMobileDatePicker-picker {
  height: calc(100vh - 236px); // 236 is equal to all the headers heights
  overflow: scroll;

  &--withRestrictions {
    height: calc(100vh - (236px + 64px));
  }

  &--filled {
    padding-bottom: 120px; // Arbitrary amount linked to the ai-multi-date-picker widget height
  }

  &::-webkit-scrollbar {
    width: constants.$inner-01;
    background-color: colors.$gold-100;
  }
  &::-webkit-scrollbar-thumb {
    background: colors.$gold-700;
  }
}

.AiMobileDatePicker-checkLabel {
  margin-bottom: constants.$margin-00;
}
</style>
