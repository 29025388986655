<template>
  <div :class="inputClasses">
    <div class="AiInput-inputGroup">
      <ai-typo
        v-if="label && type !== 'custom'"
        class="AiInput-label"
        variant="overline-02"
        as="label"
        :for="'input-' + type + '-' + slugify(label)">
        {{ label }}
      </ai-typo>
      <ai-typo
        v-else-if="label && type === 'custom'"
        :id="'input-' + type + '-' + slugify(label)"
        class="AiInput-label"
        variant="overline-02"
        as="span">
        {{ label }}
      </ai-typo>

      <div class="AiInput-inputWrapper">
        <ai-typo
          v-if="type === 'custom'"
          ref="input"
          class="AiInput-input AiInput-input--custom"
          :aria-labelledby="'input-' + type + '-' + slugify(label)"
          as="span">
          {{ modelValue ?? placeholder }}
        </ai-typo>

        <select
          v-else-if="type === 'select'"
          :id="'input-' + type + '-' + slugify(label)"
          ref="input"
          v-model="currentValue"
          class="AiInput-input AiInput-input--custom">
          <option v-if="placeholder" value="" disabled selected>
            {{ placeholder }}
          </option>
          <option
            v-for="option in options"
            :key="option.value as string"
            :value="option.value">
            {{ option.label }}
          </option>
        </select>

        <input
          v-else
          :id="'input-' + type + '-' + slugify(label)"
          ref="input"
          v-model="currentValue"
          class="AiInput-input"
          :type="inputType || type"
          :placeholder="placeholder"
          :disabled="disabled" />

        <button
          v-if="iconName && iconClickable"
          :id="'button-' + type + '-' + slugify(label)"
          class="AiInputIconButton"
          @click="$emit('icon-click')">
          <ai-icon :name="iconName" class="AiInput-icon" :size="18" />
        </button>
        <ai-icon
          v-else-if="iconName"
          :name="iconName"
          class="AiInput-icon"
          :size="18" />
      </div>
    </div>

    <ai-typo
      v-if="message"
      variant="legal-regular"
      as="span"
      class="AiInput-message">
      {{ message }}
    </ai-typo>
  </div>
</template>

<script setup lang="ts">
import type { IconName } from '../../atoms/AiIcon/types';

import type { AiInputOption } from './interfaces';

import { slugify } from '~~/helpers';

interface AiInputProps {
  disabled?: boolean;
  errors?: string[];
  expanded?: boolean;
  helperText?: string;
  iconName?: IconName;
  iconClickable?: boolean;
  inverse?: boolean;
  label: string;
  modelValue: unknown;
  options?: AiInputOption[];
  placeholder?: string;
  type?: 'text' | 'number' | 'select' | 'custom';
  inputType?: HTMLInputElement['type'];
  variant?: 'default' | 'error' | 'success';
}

const props = withDefaults(defineProps<AiInputProps>(), {
  disabled: false,
  errors: undefined,
  expanded: false,
  helperText: undefined,
  iconClickable: false,
  iconName: undefined,
  inputType: undefined,
  inverse: false,
  options: undefined,
  placeholder: undefined,
  type: 'text',
  variant: 'default',
});

type Emits = {
  (event: 'update:modelValue', value: AiInputProps['modelValue']): void;
  (event: 'icon-click'): void;
};

const emits = defineEmits<Emits>();

const input = ref<HTMLSelectElement | HTMLInputElement>();
defineExpose({ input });

const inputClasses = computed(() => {
  const classes = ['AiInput'];

  if (props.disabled) classes.push('AiInput--disabled');
  if (props.expanded) classes.push('AiInput--expanded');
  if (props.inverse) classes.push('AiInput--inverse');
  if (props.variant && !props.errors) classes.push(`AiInput--${props.variant}`);
  if (props.modelValue) classes.push(`AiInput--filled`);
  if (Array.isArray(props.errors) && props.errors.length > 0)
    classes.push(`AiInput--error`);

  return classes.join(' ');
});

const currentValue = computed<AiInputProps['modelValue']>({
  get() {
    return props.modelValue || '';
  },
  set(value) {
    emits('update:modelValue', value);
  },
});

const message = computed(() => {
  return (props.errors ?? []).join(' ') || props.helperText;
});
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/mixins';

.AiInput {
  display: flex;
  flex-direction: column;
  gap: constants.$inner-01;
}

.AiInput-inputGroup {
  display: flex;
  flex-direction: column;
  gap: constants.$inner-00;
  background: rgba(155, 140, 96, 0.08);
  border-bottom: 1px solid colors.$gold-800;
  padding: constants.$inner-02 constants.$inner-02 constants.$inner-01
    constants.$inner-02;
  position: relative;
  @include mixins.rem-fallback(min-width, 80);

  &:hover {
    background: rgba(155, 140, 96, 0.12);
  }

  &:focus-within {
    border-bottom: 2px solid colors.$gold-800;
  }
}

.AiInput-label {
  text-transform: uppercase;
  width: 100%;
  color: colors.$stratos-900;

  @include mixins.rem-fallback(min-width, 80);
}

.AiInput-inputWrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.AiInput-input {
  all: unset;
  width: 100%;
  position: relative;
  color: colors.$stratos-500;
  font-family: 'Montserrat', sans-serif;
  text-overflow: ellipsis;
  white-space: nowrap;

  &::placeholder {
    color: colors.$stratos-700;
  }

  &.AiInput-input--custom {
    cursor: pointer;
  }
}

.AiInput-input:focus {
  &::placeholder {
    opacity: 0.4;
  }
}

.AiInputIconButton {
  display: flex;
}

.AiInput-icon {
  margin-left: constants.$margin-00;
  color: colors.$gold-800;

  &.AiIcon--chevron {
    transform: rotate(180deg);
  }
}

.AiInput.AiInput--filled {
  .AiInput-label {
    color: colors.$stratos-500;
  }

  .AiInput-input {
    color: colors.$stratos-900;
  }
}

.AiInput.AiInput--expanded {
  .AiInput-icon {
    &.AiIcon--chevron {
      transform: none;
    }
  }
}

.AiInput.AiInput--error {
  .AiInput-inputGroup {
    border-bottom: 1px solid colors.$red-700;
  }

  .AiInput-message {
    color: colors.$red-700;
  }
}

.AiInput.AiInput--success {
  .AiInput-inputGroup {
    border-bottom: 1px solid colors.$green-500;
  }

  .AiInput-message {
    color: colors.$green-500;
  }
}

.AiInput.AiInput--disabled {
  .AiInput-inputGroup {
    background: rgba(155, 140, 96, 0.05);
    border-bottom: 1px solid colors.$neutral-300;
  }

  .AiInput-label {
    color: colors.$neutral-500;
  }

  .AiInput-input {
    color: colors.$stratos-700;
  }

  .AiInput-icon {
    color: colors.$neutral-300;
  }

  .AiInput-message {
    color: colors.$neutral-500;
  }
}

.AiInput.AiInput--inverse {
  .AiInput-inputGroup {
    background: rgba(255, 255, 255, 0.12);
    border-bottom: 1px solid colors.$gold-400;

    &:hover {
      background: rgba(255, 255, 255, 0.08);
    }

    &:focus-within {
      border-bottom: 2px solid colors.$gold-400;

      .AiInput-icon {
        color: colors.$gold-400;
      }
    }
  }

  .AiInput-label {
    color: colors.$white;
  }

  .AiInput-input {
    color: colors.$neutral-400;

    &::placeholder {
      color: colors.$neutral-400;
    }
  }

  .AiInput-icon {
    color: colors.$gold-300;
  }

  .AiInput-message {
    color: colors.$neutral-400;
  }

  &.AiInput--filled {
    .AiInput-label {
      color: colors.$neutral-400;
    }

    .AiInput-input {
      color: colors.$white;
    }
  }

  &.AiInput--error {
    .AiInput-inputGroup {
      border-bottom: 1px solid colors.$red-400;
    }

    .AiInput-message {
      color: colors.$red-400;
    }
  }

  &.AiInput--success {
    .AiInput-inputGroup {
      border-bottom: 1px solid colors.$green-400;
    }

    .AiInput-message {
      color: colors.$green-400;
    }
  }

  &.AiInput--disabled {
    .AiInput-inputGroup {
      background: rgba(155, 140, 96, 0.04);
      border-bottom: 1px solid colors.$neutral-600;
    }

    .AiInput-label {
      color: colors.$neutral-500;
    }

    .AiInput-input {
      color: colors.$neutral-300;

      &::placeholder {
        color: colors.$neutral-300;
      }
    }

    .AiInput-icon {
      color: colors.$neutral-500;
    }
  }
}
</style>
