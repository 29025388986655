<template>
  <ai-multi-date-picker
    ref="datepicker"
    v-model="dates"
    :disabled="disabled"
    :minimum-stay-duration="minimumStayDuration">
    <template #displayed-input>
      <ai-field-input
        :disabled="disabled"
        :errors="errors"
        :inverse="inverse"
        :label="label"
        :model-value="datesLabel"
        :name="name"
        :placeholder="placeholder"
        :successes="successes"
        :data-testid="$attrs['data-testid']"
        class="AiFormDates-input"
        readonly
        @keydown.enter="openDatepicker">
        <template #right>
          <ai-icon
            :color="inverse ? 'gold-400' : 'gold-800'"
            :size="16"
            name="calendar" />
        </template>
      </ai-field-input>
    </template>
  </ai-multi-date-picker>
</template>

<script lang="ts" setup>
// eslint-disable-next-line vue/no-dupe-keys
import { format } from 'date-fns';

import { formatDate } from '~/helpers/date.helper';

import type AiIcon from '../../atoms/AiIcon/AiIcon.vue';
import type AiFieldInput from '../../molecules/AiForm/AiFieldInput.vue';
import type AiMultiDatePicker from '../AiDatePicker/AiMultiDatePicker.vue';

type Props = {
  modelValue: Date | Date[];
  readonly?: boolean;
  format?: string;
  bypassTeleport?: boolean;
  minimumStayDuration?: number;
  // Field props
  inverse?: boolean;
  placeholder?: string;
  label?: string;
  name?: string;
  errors?: string[];
  successes?: string[];
  disabled?: boolean;
};

type Emits = {
  (event: 'update:modelValue', value: Props['modelValue']): void;
};

const props = withDefaults(defineProps<Props>(), {
  errors: undefined,
  format: 'dd LLL',
  label: undefined,
  name: undefined,
  placeholder: undefined,
  successes: undefined,
  minimumStayDuration: undefined,
});
const emits = defineEmits<Emits>();

const datepicker = ref<typeof AiMultiDatePicker>();

const dates = computed({
  get() {
    return props.modelValue;
  },
  set(value: Props['modelValue']) {
    if (Array.isArray(value) && value.length === 2) {
      const firstDate = formatDate(value[0], 'yyyy-MM-dd');
      const secondDate = formatDate(value[1], 'yyyy-MM-dd');

      if (firstDate === secondDate) {
        emits('update:modelValue', []);
        return nextTick(() => datepicker.value?.datepicker?.openMenu?.());
      }
    }

    emits('update:modelValue', value);
  },
});

const dateFnsLocale = useDateFnsLocale();

const datesLabel = computed(() => {
  if (!dates.value || (Array.isArray(dates.value) && dates.value.length <= 0))
    return;

  const rawFirstDate = (dates.value as Date[])[0];
  const rawSecondDate = (dates.value as Date[])[1];

  const firstDate = props.format
    ? format(rawFirstDate, props.format, { locale: dateFnsLocale.value })
    : rawFirstDate.toString();
  const secondDate = props.format
    ? format(rawSecondDate, props.format, { locale: dateFnsLocale.value })
    : rawSecondDate.toString();

  return `${firstDate} - ${secondDate}`;
});

const openDatepicker = () => {
  if (!datepicker.value) return;

  datepicker.value.openDatepicker();
};

defineExpose({ openDatepicker });
</script>

<style lang="scss" scoped>
.AiFormDates-input {
  cursor: pointer;
}
</style>
