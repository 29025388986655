<template>
  <ai-mobile-drawer-form
    v-model="open"
    v-model:page="page"
    :titles="allTitles"
    @popin-close="$emit('close')">
    <!-- Default -->
    <template #default>
      <div
        :class="{ 'AiBookingMobileForm-page--error': hasError }"
        class="AiBookingMobileForm-page">
        <slot
          :set-page="(p: string | undefined) => (page = p)"
          name="mobile-default-before" />

        <ai-field-input
          :errors="datesErrors"
          :label="$t('booking.bar.inputs.date.label')"
          :model-value="dateLabel"
          :placeholder="$t('booking.bar.inputs.date.placeholder')"
          class="AiBookingMobileForm-input"
          name="dates-mobile"
          readonly
          @click="page = 'dates'"
          @keydown.enter.prevent="page = 'dates'">
          <template #right>
            <ai-icon
              :size="18"
              class="AiBookingMobileForm-icon"
              name="calendar" />
          </template>
        </ai-field-input>

        <ai-field-input
          :label="$t('booking.bar.inputs.roomAndGuest.label')"
          :model-value="guestsLabel"
          :placeholder="$t('booking.bar.inputs.roomAndGuest.placeholder')"
          class="AiBookingMobileForm-input"
          name="roomAndGuest-mobile"
          readonly
          @click="page = 'roomsAndGuests'"
          @keydown.enter.prevent="page = 'roomsAndGuests'">
          <template #right>
            <ai-icon :size="18" class="AiBookingMobileForm-icon" name="guest" />
          </template>
        </ai-field-input>

        <booking-include-flights-checkbox
          v-if="manageFlights"
          v-model:include-flights="includeFlights"
          screen="mobile" />

        <slot
          :set-page="(p: string) => (page = p)"
          name="mobile-default-after" />

        <!-- Waiting for ACCORDAI-166 -->
        <!-- <ai-button
          :label="$t('booking.context.specialRates')"
          variant="tertiary"
          icon-left="plus" /> -->
      </div>
    </template>

    <!-- Dates -->
    <template #dates>
      <div
        v-if="minimumStayDuration"
        class="AiBookingMobileForm-minimumStayDuration">
        <ai-tool-tip
          :label="
            $t('search.datePicker.minimumStayDuration', { minimumStayDuration })
          "
          :size="18"
          align-item="center"
          background="aqua-blue-100"
          class="AiBookingMobileForm-minimumStayDuration-tooltip"
          fill-color="aqua-blue-700"
          text-color="aqua-blue-700"
          variant="calendar" />
      </div>
      <ai-mobile-date-picker
        v-model="dates"
        :minimum-stay-duration="minimumStayDuration"
        @select-date="setPage(undefined)" />
    </template>

    <!-- Rooms & guests -->
    <template #roomsAndGuests>
      <div
        :class="{ 'AiBookingMobileForm-page--error': hasError }"
        class="AiBookingMobileForm-page AiBookingMobileForm-page--withFooter">
        <ai-field-input
          :label="$t('booking.bar.inputs.roomAndGuest.label')"
          :model-value="guestsLabel"
          :placeholder="$t('booking.bar.inputs.roomAndGuest.label')"
          class="AiBookingMobileForm-input"
          name="roomAndGuest-single__mobile"
          readonly />

        <ai-room-and-guest-form
          v-model="rooms"
          :children-age-errors="childrenAgeErrors"
          :children-max-age="childrenMaxAge"
          show-children-age-errors />
      </div>
    </template>

    <template v-for="(_, slotName) in $slots" :key="slotName" #[slotName]>
      <div class="AiBookingMobileForm-page">
        <slot :name="slotName" />
      </div>
    </template>

    <template #footer>
      <div
        v-if="page !== 'dates' && modelValue"
        class="AiBookingMobileForm-widget">
        <div v-if="$slots.errors" class="AiBookingMobileForm-widget-errors">
          <slot name="errors" />
        </div>
        <div
          class="AiBookingMobileForm-widget-actions"
          data-testid="bookingMobileFormActions">
          <ai-button
            :label="$t('booking.bar.mobile.form.reset')"
            variant="tertiary"
            @click="reset" />

          <slot name="submit">
            <ai-button
              :label="$t('booking.bar.mobile.form.confirm')"
              @click="$emit('submit')" />
          </slot>
        </div>
      </div>
    </template>
  </ai-mobile-drawer-form>
</template>

<script lang="ts" setup>
import { format } from 'date-fns';

import { event } from '~/helpers';

import { useLayoutStore } from '../../layout.store';
import AiMobileDatePicker from '../AiForm/AiMobileDatePicker.vue';
import AiMobileDrawerForm from '../AiForm/AiMobileDrawerForm.vue';
import AiRoomAndGuestForm from '../AiForm/AiRoomAndGuestForm.vue';

type Props = {
  modelValue: boolean;
  dateFormat?: string;
  dates: Date[];
  datesErrors?: string[];
  hasError?: boolean;
  includeFlights?: boolean;
  manageFlights?: boolean;
  minimumStayDuration?: number;
  roomsAndGuests: { adults: number; childrenAges: number[] }[];
  roomsAndGuestsErrors?: { roomIndex: number; childrenAges: number[] }[];
  titles?: Record<string, string>;
  childrenMaxAge?: number;
};
const props = withDefaults(defineProps<Props>(), {
  dateFormat: 'dd LLL',
  datesErrors: undefined,
  includeFlights: false,
  manageFlights: false,
  minimumStayDuration: undefined,
  childrenMaxAge: undefined,
  roomsAndGuestsErrors: () => [],
  titles: () => ({}),
});

type Emits = {
  (event: 'update:model-value', value: Props['modelValue']): void;
  (event: 'update:dates', value: Props['dates']): void;
  (event: 'update:rooms-and-guests', value: Props['roomsAndGuests']): void;
  (event: 'update:includeFlights', value: boolean): void;
  (event: 'submit'): void;
  (event: 'close'): void;
  (event: 'resetAll'): void;
};
const emits = defineEmits<Emits>();

const layoutStore = useLayoutStore();

const page = ref();

const open = computed({
  get() {
    if (props.modelValue) {
      event('view_booking_form', {
        category: 'search',
        action: 'view booking form',
        label: 'simplified form',
      });
    }
    return props.modelValue;
  },
  set(value: Props['modelValue']) {
    emits('update:model-value', value);
  },
});

watch(open, opened => {
  layoutStore.setScrollLocked(opened);
  if (opened) {
    focusOnFirstInput();
  }
});
onBeforeMount(() => layoutStore.setScrollLocked(false));

const dates = computed({
  get() {
    return props.dates;
  },
  set(value: Props['dates']) {
    emits('update:dates', value);
  },
});

const roomsAndGuests = computed({
  get() {
    return props.roomsAndGuests;
  },
  set(value: Props['roomsAndGuests']) {
    emits('update:rooms-and-guests', value);
  },
});

const childrenAgeErrors = computed(() => {
  const errors = [];

  for (const error of props.roomsAndGuestsErrors) {
    errors[error.roomIndex] = [] as boolean[];

    for (const childrenIndex of error.childrenAges) {
      errors[error.roomIndex][childrenIndex] = true;
    }
  }

  return errors;
});

const includeFlights = computed({
  get() {
    return props.includeFlights;
  },
  set(value: boolean) {
    emits('update:includeFlights', value);
  },
});

const { t } = useI18n();

const allTitles = computed(() => ({
  dates: t('booking.bar.mobile.form.title.dates'),
  default: t('booking.bar.mobile.form.title.default'),
  roomsAndGuests: t('booking.bar.mobile.form.title.roomsAndGuests'),
  ...props.titles,
}));

const reset = () => {
  emits('resetAll');
  dates.value = [];
  roomsAndGuests.value = [{ adults: 2, childrenAges: [] }];
};

// Default
const dateFnsLocale = useDateFnsLocale();

const dateLabel = computed(() => {
  if (!Array.isArray(props.dates) || props.dates.length < 2) return;

  const [rawFirstDate, rawLastDate] = props.dates;

  const firstDate = props.dateFormat
    ? format(rawFirstDate, props.dateFormat, { locale: dateFnsLocale.value })
    : rawFirstDate.toString();
  const lastDate = props.dateFormat
    ? format(rawLastDate, props.dateFormat, { locale: dateFnsLocale.value })
    : rawLastDate.toString();

  return t('booking.bar.mobile.dates', { firstDate, lastDate });
});

const guestsLabel = computed(() => {
  const roomsCount = props.roomsAndGuests.length;
  const guests = props.roomsAndGuests.reduce(
    (sum, room) => sum + room.adults + room.childrenAges.length,
    0,
  );

  if (!roomsCount || !guests) return;

  const roomsCountLabel = t('booking.bar.inputs.roomAndGuest.room', {
    count: roomsCount,
  });

  const guestsCountLabel = t('booking.bar.inputs.roomAndGuest.guest', {
    count: guests,
  });

  return t('booking.bar.inputs.roomAndGuest.fullValue', {
    guests: guestsCountLabel,
    rooms: roomsCountLabel,
  });
});

// Rooms & guests
const rooms = computed({
  get() {
    return props.roomsAndGuests;
  },
  set(value: Props['roomsAndGuests']) {
    emits('update:rooms-and-guests', value);
  },
});

const setPage = (pageName: string | undefined) => {
  page.value = pageName;
};

const focusOnFirstInput = () => {
  if (!process.client) {
    return;
  }
  nextTick(() => {
    const mobileForm = document
      .getElementsByClassName('AiBookingMobileForm-page')
      .item(0);

    mobileForm?.getElementsByTagName('input').item(0)?.focus();
  });
};

defineExpose({ setPage });
</script>

<style lang="scss" scoped>
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/functions' as func;

.AiBookingBarMobileForm-headerLeft {
  position: absolute;
  top: calc(50% - 12px); // 12px for half button height
  left: constants.$padding-01;
}

.AiBookingMobileForm-page {
  padding: constants.$padding-02;
  height: 100%;
  background-color: colors.$gold-100;
  /* overflow: visible; */

  &--withFooter {
    padding-bottom: calc(constants.$padding-02 + func.calcRem(160));
    overflow: scroll;

    &::-webkit-scrollbar {
      width: constants.$inner-01;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: colors.$gold-700;
    }
  }

  &--error {
    margin-bottom: func.calcRem(20);

    &.AiBookingMobileForm-page--withFooter {
      // 160 for footer height + no availability message
      padding-bottom: calc(constants.$padding-02 + func.calcRem(160));
    }
  }
}

.AiBookingMobileForm-minimumStayDuration {
  padding-top: constants.$padding-01;
  padding-left: constants.$padding-01;
  padding-right: constants.$padding-01;
  background-color: colors.$gold-100;
}

.AiBookingMobileForm-minimumStayDuration-tooltip {
  justify-content: center;
  align-items: center;
  color: colors.$aqua-blue-700;
}

.AiBookingMobileForm-input {
  margin-bottom: constants.$margin-04;
}

.AiBookingMobileForm-icon {
  color: colors.$gold-900;
}

.AiBookingMobileForm-rooms {
  margin-bottom: constants.$margin-02;
}

.AiBookingMobileForm-childrenAges {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: constants.$inner-05;
  margin-top: constants.$margin-01;
}

.AiBookingMobileForm-guests:not(:first-of-type) {
  margin-top: constants.$margin-03;
}

.AiBookingMobileForm-widget {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: colors.$white;
  padding: constants.$padding-02;
  z-index: 200;
  box-shadow: 0px -12px 12px rgba(155, 140, 96, 0.1);
}

.AiBookingMobileForm-widget-errors {
  margin-bottom: constants.$inner-03;
}

.AiBookingMobileForm-widget-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
