const internalState = ref<Date[]>([]);

export function useDatepickerInternalValue() {
  const updateDate = (newDates: Date[]) => {
    internalState.value = newDates;
  };

  const dates = computed(() => {
    return internalState.value;
  });

  return {
    dates,
    updateDate,
  };
}
