<template>
  <div
    :class="{
      'BookingBarIncludeFlightsCheckbox--desktop': screen === 'desktop',
      'BookingBarIncludeFlightsCheckbox--inverse': inverse,
      'BookingBarIncludeFlightsCheckbox--mobile': screen === 'mobile',
      'BookingBarIncludeFlightsCheckbox--slim': slim,
    }"
    class="BookingBarIncludeFlightsCheckbox">
    <ai-checkbox-input
      id="includeFlights"
      v-model="includeFlights"
      :variant="inverse ? 'inverse' : 'positive'"
      @change="onCheckboxChange" />
    <label for="includeFlights">
      {{ $t('booking.bar.inputs.includeFlights.label') }}
    </label>
  </div>
</template>

<script lang="ts" setup>
import { event, getPageName } from '~/helpers';

type Props = {
  includeFlights: boolean;
  inverse?: boolean;
  screen?: 'mobile' | 'desktop';
  slim?: boolean;
};
const props = withDefaults(defineProps<Props>(), {
  inverse: false,
  screen: 'desktop',
  slim: false,
});

type Emits = {
  (event: 'update:includeFlights', value: boolean): void;
};
const emit = defineEmits<Emits>();

const route = useRoute();

const includeFlights = computed({
  get: () => props.includeFlights,
  set: (value: boolean) => {
    emit('update:includeFlights', value);
  },
});

const pageName = computed<string>(() =>
  route.name ? getPageName(route.name.toString()) : 'unknown',
);

const onCheckboxChange = (checkboxEvent: Event) => {
  const target = checkboxEvent.target as HTMLInputElement;

  event('flights_checkbox', {
    action: 'flights checkbox',
    category: pageName.value,
    label: target.checked ? 'check' : 'uncheck',
  });
};
</script>

<style lang="scss" scoped>
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/mq';

.BookingBarIncludeFlightsCheckbox {
  display: flex;
  align-items: center;
  gap: constants.$margin-00;
  background-color: colors.$gold-100;
  padding: 0 constants.$padding-03 constants.$padding-02;
  margin-top: 0;

  label {
    color: colors.$gold-900;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.BookingBarIncludeFlightsCheckbox--desktop {
  display: none;

  @media (mq.$from-large) {
    display: flex;
  }

  &.BookingBarIncludeFlightsCheckbox--slim {
    padding: 0;
    margin-top: constants.$margin-02;
  }
}

.BookingBarIncludeFlightsCheckbox--mobile {
  display: flex;
  padding: constants.$padding-02 0 0;

  @media (mq.$from-large) {
    display: none;
  }
}

.BookingBarIncludeFlightsCheckbox--inverse {
  background-color: transparent;

  label {
    color: colors.$gold-300;
  }
}
</style>
