<template>
  <ai-drawer
    v-model="open"
    :title="title"
    large-header
    no-click-outside
    :show-header-left="showBack"
    @update:page="v => $emit('update:page', v)"
    @popin-close="closePopin">
    <slot v-if="!page || page === 'default'" name="default" />

    <slot :name="page" />
    <slot name="footer" />
  </ai-drawer>
</template>

<script setup lang="ts">
import AiDrawer from '../../molecules/AiDrawer/AiDrawer.vue';

type Props = {
  modelValue: boolean;
  titles: { default: string; [key: string]: string };
  page?: string;
};

type Emits = {
  (event: 'update:model-value', value: Props['modelValue']): void;
  (event: 'update:page', value: Props['page']): void;
  (event: 'popin-close'): void;
};

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const open = computed({
  get() {
    return props.modelValue;
  },
  set(value: Props['modelValue']) {
    emits('update:model-value', value);
  },
});

const page = computed({
  get() {
    return props.page;
  },
  set(value: Props['page']) {
    emits('update:page', value);
  },
});

const title = computed(() =>
  !page.value ? props.titles.default : props.titles[page.value],
);

const showBack = computed(() => Boolean(page.value));

function closePopin() {
  if (page.value === undefined) {
    emits('popin-close');
  } else {
    emits('update:page', undefined);
  }
}
</script>
